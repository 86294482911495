import {Link} from "react-router-dom"
import useAuthStore from "../store/useAuthStore";

export default function Home() {
    return (
        <div>
            <h1>Home</h1>
            <p>가장 먼저 보여지는 페이지입니다.</p>
            <Link to="/users">고객페이지 이동</Link>
            <br/>
            <Link to="/sellers">강사페이지 이동</Link>
        </div>
    );
}