import React from 'react';
import { ReactComponent as LogoSVG } from '../logo320.svg';
import {Link} from "react-router-dom"; // SVG 파일을 컴포넌트로 import

const Logo = () => {
    return (
        <div className="logo">
            <Link to="/"><LogoSVG width="100%" /></Link> {/* 크기와 스타일을 props로 지정 가능 */}
        </div>
    );
};

export default Logo;