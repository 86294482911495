export type formatRule = (value: string) => string;


export const formatPhone = (value: string): string => {
    const cleaned = value.replace(/\D/g, ''); // 숫자만 남김
    return cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
};

export const formatCurrency = (value: string): string => {
    const cleaned = value.replace(/\D/g, ''); // 숫자만 남김
    return cleaned.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatTrim = (value: string): string =>
    value.replace(/\s+/g, '');

export const formatNumber = (value: string): string =>
    value.replace(/[^0-9]/g,'');

export const trimFormData = (data: { [key: string]: any }): { [key: string]: any } => {
    return Object.keys(data).reduce((acc, key) => {
        const value = data[key];
        acc[key] = typeof value === 'string' ? value.trim() : value;
        return acc;
    }, {} as { [key: string]: any });
};

export const formatter = (value: string, rule: formatRule): string  => {

    return rule(value);

};