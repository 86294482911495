import React, {useEffect, useState} from 'react';
import Logo from "../Logo";
import FormField from "../FormField/FormField";
import {useLocation, useNavigate} from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import useAuthStore from "../../store/useAuthStore";
import useModalStore from "../../store/useModalStore";
import Alert from "../Alert";
import {confirmPassword, required, korean, validate, email, password, phone} from "../../utils/validation";
import {formatNumber, trimFormData} from "../../utils/format";


const SignUp: React.FC = () => {
    const {signUp} = useAuthStore()
    const {openModal} = useModalStore();
    const [phoneCheckMessage, setPhoneCheckMessage] = useState<string | null>(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [buttonStates, setButtonStates] = useState<{ [key: string]: boolean }>({
        button1: false,
        button2: false,
    });
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        companyName: 'test',
        industryCode: 'IND002',
        inflowType: 'SEARCH_NAVER',
        agreeMarketing: true,
    });
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || '/';
    const role = location.pathname.startsWith('/sellers') ? 'SELLER' : 'USER';
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        console.log(validateForm())
    };

    useEffect(() => {
        // formData가 변경될 때마다 유효성 검사 수행
        setIsFormValid(validateForm());
    }, [formData]); // formData가 변경될 때 실행

    const validateForm = () => {
        const emailError = validate(formData.email, [required, email]);
        const passwordError = validate(formData.password, [required, password]);
        const confirmPasswordError = validate(formData.confirmPassword, [required, confirmPassword(formData.password)]);
        const phoneNumberError = validate(formData.phoneNumber, [required, phone]);
        const nameError = validate(formData.name, [required, korean]);


        setButtonStates({button1: !emailError, button2: !phoneNumberError });


        // 모든 필드가 유효하면 true 반환
        return !emailError && !passwordError && !confirmPasswordError && !phoneNumberError && !nameError;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsFormValid(validateForm());
        if(!validateForm()) return;
        try {
            await signUp(formData.name, formData.email, formData.password, formatNumber(formData.phoneNumber), formData.companyName, formData.industryCode, role, formData.inflowType, formData.agreeMarketing);
            openModal(`회원가입 완료: ${formData.name}`);
            // if(isLoggedIn) navigate(from, { replace: true });
        } catch (error: any) {
            // alert(error);
            openModal(`Error: ${error.response.data.data.codeDetail}`);
            // console.error('Login error:', error);
            // alert('Invalid login credentials');
        }
        // 회원가입 처리 로직

    };

    const checkEmail = () => {
        return;
    };

    const checkPhone = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if(validate(formData.phoneNumber, [required, phone])) return;
        try {

            const response = await axiosInstance.post('/auth/existence', {
                phoneNumber: formatNumber(formData.phoneNumber),
                roleType: role
            });
            console.log(response)
            if (!response.data.data.duplicated) {
                setPhoneCheckMessage('사용 가능한 전화번호입니다.');
            } else {
                setPhoneCheckMessage('이미 사용 중인 전화번호.');
            }
        } catch (error) {
            console.error('전화번호 중복 확인 실패:', error);
            setPhoneCheckMessage('전화번호 확인 중 오류가 발생했습니다.');
        }
    }

    return (
        <div className="auth-container">
            <Logo/>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <FormField
                        label="이메일"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        name="email"
                        placeholder="이메일 주소(아이디)입력"
                        validationRules={[required]}
                        button={<button onClick={checkEmail} disabled={!buttonStates.button1}>중복확인</button>}
                    />
                    <FormField
                        label="비밀번호"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        name="password"
                        validationRules={[required]}
                        placeholder="영문, 숫자를 포함한 8자 이상 20자 이하 입력"
                    />
                    <FormField
                        label="비밀번호 확인"
                        type="password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        name="confirmPassword"
                        validationRules={[required, confirmPassword(formData.password)]}
                        placeholder="비밀번호를 다시 입력하세요"
                        maxLength="20"
                    />
                    <FormField
                        label="이름"
                        value={formData.name}
                        onChange={handleChange}
                        name="name"
                        validationRules={[required, korean]}
                        placeholder="이름을 입력하세요"
                        maxLength="10"
                    />
                    <FormField
                        label="휴대전화번호"
                        type="phone"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        name="phoneNumber"
                        validationRules={[required]}
                        placeholder="API 연동예정"
                        maxLength="13"
                        button={<button onClick={checkPhone} disabled={!buttonStates.button2}>인증하기</button>}
                    />
                    {phoneCheckMessage && <p className="email-check-message">{phoneCheckMessage}</p>}
                </div>
                <button type="submit" disabled={!isFormValid}>회원가입</button>
            </form>
            <Alert />
        </div>
    );
};

export default SignUp;