import React from 'react';
import logo from './logo.svg';
import './App.scss';
import axios from 'axios';
import {Route, Router, Routes} from "react-router-dom";
import Sellers from "./pages/Sellers";
import Home from "./pages/Home";
import Users from "./pages/Users";
import Products from "./pages/Products";


import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import FindMy from "./components/auth/FindMy";
import NotFound from "./components/NotFound";
import Forbidden from "./components/Forbidden";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import Layout from "./components/layout/Layout";




const publicRoutes = [
    { path: '/sign-in', element: <SignIn /> },
    { path: '/sign-up', element: <SignUp /> },
    { path: '/find-my', element: <FindMy /> },
    { path: '/sellers/sign-in', element: <SignIn /> },
    { path: '/sellers/sign-up', element: <SignUp /> },
    { path: '/sellers/find-my', element: <FindMy /> },
];

function App() {
    return (

        <Routes>

            {publicRoutes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        <PublicRoute>
                            {route.element}
                        </PublicRoute>
                    }
                />
            ))}

            <Route element={<Layout />}>
                <Route path="/" element={<Home/>}>

                </Route>
            </Route>



            <Route path="/users" element={<ProtectedRoute requiredRole="USER" />}>
                <Route element={<Layout />}>
                    <Route index element={<Users />}/>                        {/* 자식 경로 */}
                    <Route path="products" element={<Products />}/>
                </Route>
            </Route>

            <Route path="/sellers" element={<ProtectedRoute requiredRole="SELLER" />}>
                <Route element={<Layout />}>
                    <Route index element={<Sellers />}/>                        {/* 자식 경로 */}
                    <Route path="products" element={<Products />}/>
                </Route>
            </Route>

            <Route path="/forbidden" element={<Forbidden/>}/>
            <Route path={"*"} element={<NotFound/>}/>
        </Routes>


    );
}

export default App;
