import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from "../store/useAuthStore";
import CenterContainer from "./layout/CenterContainer";

interface PublicRouteProps {
    children: React.ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
    const { isLoggedIn, user } = useAuthStore();

    // 로그인된 상태라면 홈 페이지로 리다이렉트
    if (isLoggedIn) {
        const to = user?.role == 'SELLER' ? '/sellers' : '/';
        return <Navigate to={to} />;
    }

    return <CenterContainer>{children}</CenterContainer>;
};

export default PublicRoute;