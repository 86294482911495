import React from 'react';
import '../../styles/Checkbox.scss';
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement>{
    label: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange, ...props }) => {
    return (
        <label className={`checkbox ${props.className ? props.className : ''}`}>
            <input
                type="checkbox"
                name={props.name}
                checked={checked}
                onChange={onChange}
            />
            <span className="checkbox-box">
                {checked && <FontAwesomeIcon icon={faCheck} className="check-icon" />}
            </span>
            {label}
        </label>
    );
};

export default Checkbox;