import React, {useState} from 'react';
import '../../styles/Input.scss';
import {
    validate,
    required,
    email,
    minLength,
    password,
    ValidationRule,
    confirmPassword,
    phone
} from '../../utils/validation';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faAsterisk, faEye, faEyeSlash, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {formatCurrency, formatPhone, formatTrim} from "../../utils/format";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    icon?: IconProp;
    type?: string;
    value?: string;
    button?: React.ReactNode; // 버튼을 추가할 수 있는 prop
    validationRules?: ValidationRule[];
}

const Input: React.FC<InputProps> = ({label, icon, type, value, button, onChange, validationRules = [], ...props}) => {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };

    const [error, setError] = useState<string | null>(null);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name} = e.target;
        let newValue = e.target.value;
        let defaultValidationRules = validationRules;
        switch (type) {
            case "email" :
                newValue = formatTrim(newValue);
                defaultValidationRules.push(email);
                break;
            case "password" :
                newValue = formatTrim(newValue);
                if (props.name == 'password') {
                    defaultValidationRules.push(password);
                }
                break;
            case "phone" :
                newValue = formatPhone(newValue);
                defaultValidationRules.push(phone);
                break;
            case "currency" :
                newValue = formatCurrency(newValue);
                break;
        }
        const errorMessage = validate(newValue, validationRules);
        // Trigger external onChange handler
        if (onChange) {
            onChange({
                ...e,
                target: {
                    ...e.target,
                    name: props.name,
                    value: newValue, // 기존 value를 업데이트
                },
            } as React.ChangeEvent<HTMLInputElement>);
        }
        setError(errorMessage);
        // if (error) setError(null); // 입력 중에는 에러 메시지 초기화
    };

    const handleBlur = () => {




    };


    return (
        <div className={`input-wrapper${button ? ' has-button' : ''}`}>
            {label && <label htmlFor={props.name} className="input-label">
                {label}{validationRules.includes(required) && (
                <FontAwesomeIcon icon={faAsterisk} className="required"/>)}
            </label>}
            <div className={`input-field${icon ? ' has-icon' : ''}`}>
                <div className="input-group">
                    {icon && (
                        <FontAwesomeIcon icon={icon} className="input-icon left"/>
                    )}
                    <input
                        {...(label ? {id: props.name} : {})}
                        type={isPasswordVisible && type === "password" ? "text" : type}
                        value={value}
                        className="input-field"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        {...props}
                    />

                    {props.name === "password" && (
                        <FontAwesomeIcon
                            icon={isPasswordVisible ? faEyeSlash : faEye}
                            className="input-icon right"
                            onClick={togglePasswordVisibility}
                        />
                    )}
                </div>
                {error &&
                    <span className="input-error"><FontAwesomeIcon icon={faXmark} className="required"/>{error}</span>}
            </div>
            {button && (
                <div className="input-button">
                    {button}
                </div>
            )}
        </div>
    );
};

export default Input;