export type ValidationRule = (value: string) => string | null;

export const required: ValidationRule = (value) =>
    value.trim() ? null : 'This field is required';

export const minLength = (min: number): ValidationRule => (value) =>
    value.length >= min ? null : `Minimum length is ${min} characters`;

export const maxLength = (max: number): ValidationRule => (value) =>
    value.length <= max ? null : `Maximum length is ${max} characters`;

export const email: ValidationRule = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? null : 'Please enter a valid email address';
};

export const password: ValidationRule = (value) => {
    const minLengthRule = minLength(8);
    const hasNumber = /[0-9]/.test(value);
    const hasLetter = /[a-zA-Z]/.test(value);

    if (minLengthRule(value)) return minLengthRule(value);
    if (!hasNumber || !hasLetter) return 'Password must include letters and numbers';
    return null;
};

export const confirmPassword = (originalPassword: string): ValidationRule => (value) =>
    value === originalPassword ? null : 'Passwords do not match';

export const phone: ValidationRule = (value) => {
    const phoneRegex = /^\d{3}-\d{4}-\d{4}$/;
    return phoneRegex.test(value) ? null : 'Please enter a valid phoneNumber';
};

export const korean: ValidationRule = (value) => {
    const emailRegex = /^[ㄱ-ㅎ가-힣]+$/;
    return emailRegex.test(value) ? null : '';
};

export const validText: ValidationRule = (value) => {
    const emailRegex = /^[ㄱ-ㅎ가-힣a-zA-Z0-9]+$/;
    return emailRegex.test(value) ? null : 'Please enter a valid name';
};

// 특정 필드에 대한 여러 규칙을 적용할 수 있는 유틸리티 함수
export const validate = (value: string, rules: ValidationRule[]): string | null => {
    for (let rule of rules) {
        const error = rule(value);
        if (error) return error;
    }
    return null;
};