// src/components/ProtectedRoute.tsx

import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import useAuthStore from "../store/useAuthStore";


interface ProtectedRouteProps {
    requiredRole?: string; // 접근에 필요한 역할
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredRole  }) => {
    const { isLoggedIn, user } = useAuthStore();
    const location = useLocation(); // 현재 경로를 가져오기
    const role = location.pathname.startsWith('/sellers') ? 'SELLER' : 'USER';
    const to = role == 'SELLER' ? '/sellers/sign-in' : '/sign-in';
    console.log(requiredRole);
    console.log(role);
    if (!isLoggedIn) {
        // 로그인 페이지로 리다이렉트 (또는 원하는 경로)
        return <Navigate to={to} state={{ from: location, role: role }} replace />;
    }

    if (requiredRole && user?.role !== requiredRole) {
        // 사용자 역할이 필요한 역할과 다르면 접근 금지 페이지로 리다이렉트
        return <Navigate to="/forbidden" replace />;
    }

    // 회원인 경우 자식 컴포넌트를 렌더링
    return <Outlet />;
};

export default ProtectedRoute;