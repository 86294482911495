import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import useAuthStore from "../../store/useAuthStore";
import FormField from "../FormField/FormField";
import Logo from "../Logo";
import Alert from "../Alert";
import useModalStore from "../../store/useModalStore";
import {faEnvelope, faLock} from "@fortawesome/pro-regular-svg-icons";
import Checkbox from "../FormField/Checkbox";
import {confirmPassword, email, korean, password, phone, required, validate} from "../../utils/validation";


const SignIn: React.FC = () => {
    const [formData, setFormData] = useState({signInId: '', password: '', rememberMe: false});
    const [isFormValid, setIsFormValid] = useState(false);
    const {signIn, shouldPersist} = useAuthStore();
    const {openModal} = useModalStore();
    const navigate = useNavigate();
    const location = useLocation();


    const from = location.state?.from?.pathname || '/';
    const role = location.pathname.startsWith('/sellers') ? 'SELLER' : 'USER';
    const to = role == 'SELLER' ? '/sellers' : '';

    // const handleLogin = () => {
    //     if (username.trim()) {
    //         // login(username);
    //         navigate(from, { replace: true }); // 로그인 후 이전 페이지로 이동
    //     }
    // };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, type, value, checked} = e.target;
        setFormData((prev) => ({...prev, [name]: type === 'checkbox' ? checked : value}));
    };

    useEffect(() => {
        // formData가 변경될 때마다 유효성 검사 수행
        setIsFormValid(validateForm());
    }, [formData]); // formData가 변경될 때 실행

    const validateForm = () => {
        const emailError = validate(formData.signInId, [required, email]);
        const passwordError = validate(formData.password, [required, password]);

        // 모든 필드가 유효하면 true 반환
        return !emailError && !passwordError;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await signIn(formData.signInId, formData.password, role);
            openModal(`로그인 완료`);
            navigate(from, {replace: true});
        } catch (error: any) {
            console.error('Login error:', error.response.data.data);
            openModal(`Error: ${error.response.data.data.codeDetail}`);
        }

        // try {
        //     if (response.status === 200) {
        //         login(formData.username, formData.password, role);
        //         navigate(from, { replace: true });
        //     }
        // } catch (error) {
        //     alert('Login failed. Please try again.');
        // }//     const response = await axios.post('/api/login', formData);

        //
        //
        // if (formData.username && formData.password) {
        //     // 간단한 로그인 로직 - 실제 환경에서는 백엔드 API 호출 필요
        //     login(formData.username, role);
        //     navigate(from, { replace: true }); // 로그인 후 대시보드로 리다이렉트
        // } else {
        //     alert('Please fill in all fields');
        // }
    };

    return (
        <div className="auth-container">
            <Logo/>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <FormField
                        type="email"
                        // value={formData.signInId}
                        icon={faEnvelope}
                        onChange={handleChange}
                        name="signInId"
                        placeholder="이메일 주소(아이디)입력"
                    />
                    <FormField
                        type="password"
                        // value={formData.password}
                        icon={faLock}
                        onChange={handleChange}
                        name="password"
                        placeholder="비밀번호 입력"
                    />
                    <div className="form-field">
                        <Checkbox
                            label="아이디 저장"
                            name="rememberMe"
                            className="sm"
                            checked={formData.rememberMe}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <button type="submit" disabled={!isFormValid}>로그인</button>
                <div className="sign-in-action">
                    <Link to={to + '/sign-up'} state={{role: role}}>회원가입</Link>
                    <Link to={to + '/find-my'} state={{role: role}}>비밀번호찾기</Link>
                </div>
            </form>
            <Alert/>
        </div>
    );
};

export default SignIn;
