import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';
import axiosInstance from "../utils/axiosInstance";
import axios from 'axios';

interface AuthStore {
    isLoggedIn: boolean;
    shouldPersist: boolean;
    user: { name: string; role: string } | null; // role 속성 추가
    token: string | null;
    signIn: (signInId: string, password: string, roleType: string, rememberMe?: boolean) => Promise<void>;
    signOut: () => void;
    signUp: (name: string, email: string, password: string, phoneNumber: string, companyName: string, industryCode: string, roleType: string, inflowType: string, agreeMarketing: boolean) => Promise<void>;
    findMyEmail: (name: string, phoneNumber: string, roleType: string) => Promise<void>;
    findMyPassword: (email: string, roleType: string) => Promise<void>;
}

const useAuthStore = create<AuthStore>()(
    persist(
        (set, get) => ({
            isLoggedIn: false,
            shouldPersist: false,
            user: null,
            token: null,
            signIn: async (signInId, password, roleType, rememberMe=false) => {
                try {
                    const response = await axiosInstance.post('/auth/sign-in', {signInId, password, roleType});

                    useAuthStore.persist.setOptions({
                        storage: createJSONStorage(() => sessionStorage),
                    })
                    const {token, user} = response.data;
                    set({
                        isLoggedIn: true,
                        shouldPersist: rememberMe,
                        user: {
                            name: response.data.data.user.id,
                            role: response.data.data.user.roleType
                        },
                        token,
                    });
                    // axios 인스턴스에 토큰 설정
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                } catch (error) {
                    console.log(error)
                    throw error;
                }
            },
            signOut: () => {
                set({isLoggedIn: false, shouldPersist:false, user: null, token: null,});
                delete axiosInstance.defaults.headers.common['Authorization'];
            },
            signUp: async (name: string, email: string, password: string, phoneNumber: string, companyName: string, industryCode: string, roleType: string, inflowType: string, agreeMarketing: boolean) => {
                try {
                    const response = await axiosInstance.post('/auth/sign-up', {
                        name,
                        email,
                        password,
                        phoneNumber,
                        companyName,
                        industryCode,
                        roleType,
                        inflowType,
                        agreeMarketing
                    });
                    if(response.status == 200) await get().signIn(email, password, roleType);

                } catch (error) {
                    console.log(error)
                    throw (error);

                }
            },
            findMyEmail: async (name: string, phoneNumber: string, roleType: string) => {
                try {
                    const response = await axiosInstance.post('/auth/find-my-email', {name, phoneNumber, roleType});
                    console.log(response.data);

                } catch (error: any) {
                    console.log(error)
                    if (error.response) {
                        console.error('Server responded with status:', error.response.status);
                        console.error('Response data:', error.response.data);
                    } else if (error.request) {
                        console.error('No response received:', error.request);
                    } else {
                        console.error('Request setup error:', error.message);
                    }
                }
            },
            findMyPassword: async (email: string, roleType: string) => {
                try {
                    const response = await axiosInstance.post('/auth/find-my-password', {email, roleType});
                    console.log(response.data);

                } catch (error: any) {
                    console.log(error)
                    if (error.response) {
                        console.error('Server responded with status:', error.response.status);
                        console.error('Response data:', error.response.data);
                    } else if (error.request) {
                        console.error('No response received:', error.request);
                    } else {
                        console.error('Request setup error:', error.message);
                    }
                }
            }
        }),
        {
            name: 'auth-storage', // localStorage에 저장될 키 이름
        }
    )
);


export default useAuthStore;