import React, { ReactNode } from 'react';


interface CenterContainerProps {
    children: ReactNode;
}

const CenterContainer: React.FC<CenterContainerProps> = ({ children }) => {
    return <div className="center-container">{children}</div>;
};

export default CenterContainer;