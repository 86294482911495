
const Forbidden = () => {

    return (
        <section>
            <h1>접근할 수 있는 권한이 없습니다.</h1>
        </section>

    );
}

export default Forbidden
