import {Link} from "react-router-dom";
import useAuthStore from "../store/useAuthStore";

const Products = () => {
    const { isLoggedIn } = useAuthStore();
    return (
        <section>
            <h1>판매자 상품리스트</h1>
            <div>
                <Link to="/sellers">대시보드 이동</Link>
            </div>
        </section>

    );
}

export default Products