import React from 'react';
import Input from "./Input";
import "../../styles/FormField.scss";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {ValidationRule} from "../../utils/validation";

// import './Input.css'; // 스타일 import

interface FormFieldProps {
    label?: string;
    value?: string;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: IconProp;
    type?: string;
    name?: string;
    maxLength?: any;
    placeholder?: string;
    button?: React.ReactNode;
    autoComplete?: string;
    required?: boolean;
    validationRules?: ValidationRule[];
}

const FormField: React.FC<FormFieldProps> = ({
                                                 label,
                                                 value,
                                                 className,
                                                 onChange,
                                                 icon,
                                                 type = "text",
                                                 name,
                                                 placeholder,
                                                 maxLength,
                                                 button,
                                                 autoComplete = "new-password",
                                                 required,
                                                 validationRules
                                             }) => {
    return (
        <div className="form-field">
            <Input
                label={label}
                value={value}
                className={className}
                onChange={onChange}
                icon={icon}
                type={type}
                name={name}
                placeholder={placeholder}
                maxLength={maxLength}
                button={button}
                autoComplete={autoComplete}
                required={required}
                validationRules={validationRules}
            />
        </div>
    );
};

export default FormField;