import {Link} from "react-router-dom";
import useAuthStore from "../store/useAuthStore";

const Users = () => {
    const { isLoggedIn, signOut } = useAuthStore();
    return (
        <section>
            <h1>고객페이지</h1>
            <div>
                <Link to="/">홈페이지 이동</Link>
                <button onClick={signOut}>Logout</button>
            </div>
        </section>

    );
}

export default Users