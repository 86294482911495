import React from 'react';
import useModalStore from '../store/useModalStore';
import '../styles/Modal.scss'; // 스타일링

const ResponseModal: React.FC = () => {
    const { isOpen, message, closeModal } = useModalStore();

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2 className="modal-title">Response</h2>
                <p className="modal-message">{message}</p>
                <button onClick={closeModal} className="modal-close-button">OK</button>
            </div>
        </div>
    );
};

export default ResponseModal;