
const NotFound = () => {

    return (
        <section>
            <h1>404 not found</h1>
        </section>

    );
}

export default NotFound
