import React, {useState} from 'react';
import FormField from "../FormField/FormField";
import axiosInstance from "../../utils/axiosInstance";
import {useLocation, useNavigate} from "react-router-dom";
import useAuthStore from "../../store/useAuthStore";

const FindMy: React.FC = () => {
    const {findMyPassword, findMyEmail} = useAuthStore()
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phoneNumber: ''
    });
    const location = useLocation();
    const role = location.pathname.startsWith('/sellers') ? 'SELLER' : 'USER';
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const handleSubmit = async (event: React.FormEvent, target: 'email' | 'password') => {
        event.preventDefault();

        if (target === 'email') {
            await findMyEmail(formData.username, formData.phoneNumber, role);
            try {

                // if(isLoggedIn) navigate(from, { replace: true });
            } catch (error) {
                console.error('Login error:', error);
                alert('Invalid login credentials');
            }
        } else if (target === 'password') {
            await findMyPassword(formData.email, role);
            try {
                alert(`회원가입 완료: ${formData.username}`);
                // if(isLoggedIn) navigate(from, { replace: true });
            } catch (error) {
                console.error('Login error:', error);
                alert('Invalid login credentials');
            }
        }


        // if (formData.password !== formData.confirmPassword) {
        //     alert('비밀번호가 일치하지 않습니다.');
        //     return;
        // }


        // 회원가입 처리 로직

    };


    return (
        <div className="auth-container">
            <form onSubmit={(e) => handleSubmit(e, 'email')}>
                <div className="input-container">
                    <h2>가입한 회원정보를 입력해주세요.</h2>
                    <FormField
                        label="이름"
                        // value={formData.username}
                        onChange={handleChange}
                        name="username"
                        placeholder="이름을 입력하세요"
                        required
                    />
                    <FormField
                        label="휴대전화번호"
                        // value={formData.phoneNumber}
                        onChange={handleChange}
                        name="phoneNumber"
                        placeholder="API 연동예정"
                        required
                    />
                </div>
                    <button type="submit">아이디찾기</button>
            </form>
            <form onSubmit={(e) => handleSubmit(e, 'password')}>
                <div className="input-container">
                    <h2>가입한 회원정보를 입력해주세요.</h2>
                    <FormField
                        label="이메일"
                        // value={formData.email}
                        onChange={handleChange}
                        name="email"
                        placeholder="이메일 주소를 입력해주세요."
                        required
                    />
                </div>
                    <button type="submit">비밀번호 초기화</button>
            </form>
        </div>


)
;
};

export default FindMy;